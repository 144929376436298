<script>
	import AIBlob from './AIBlob.svelte';
	import { Version } from '$lib/store.js';
	export let onClick, blob;
	export let isLoading; //= true;
	export let text;
	export let disabled = false;
	export let id;
	export let bclass = '';
	export let style = '';
</script>

<!-- <span class="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40"></span> -->
<button
	{id}
	{disabled}
	type="submit"
	on:click|preventDefault|stopPropagation={async () => {
		if (isLoading) return;
		isLoading = true;
		await onClick?.()?.catch?.((e) => console.error(e));
		isLoading = false;
	}}
	class="{bclass} hover:brightness-150 brightness-100 grayscale-0 transition-filter"
	{style}
>
	{#if isLoading}
		{#if false}
			<!-- <AIBlob size={32} speed={isLoading ? undefined : 0} processing={isLoading ? undefined : 0} /> -->
		{:else if $Version.app == 'omnipilot'}
			<img style="filter:none" src="/icon.png" />
		{/if}
		<span class=" animate-pulse">{text}</span>
	{:else if text}
		<!-- {#if blob}
			<AIBlob size={28} speed={0} processing={0} />
		{/if} -->
		<slot />
		<span class="">{text}</span>
	{:else}
		<slot />
	{/if}
</button>

<style>
	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	img {
		animation: rotate 1s linear infinite;
		height: 28px;
		width: 28px;
	}
	button:disabled {
		filter: grayscale(80%) brightness(0.5);
		opacity: 0.7;
		background: inherit;
	}
	.transition-filter {
		transition-property: filter, transform;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 150ms;
	}
</style>
